import { Injectable } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap
} from "rxjs/operators";


import { AlertService } from "../../commons/services/alert.service";

import * as AwsCustomerActions from "../actions/aws_customer.actions";
import * as AwsCustomerSelectors from "../selectors/aws_customer.selectors";

import { LaravelAwsCustomerService } from 'src/app/commons/services/backend/laravel-aws_customer.service';
import { AppState } from "../reducers";
import { getAwsCustomersTableState } from '../selectors/aws_customer.selectors';
import { AwsCustomerEditComponent } from "src/app/modules/shared/components/aws_customer/aws-customer-edit/aws-customer-edit.component";

@Injectable()
export class AwsCustomerEffects {
  error$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AwsCustomerActions.saveCustomerFailed, AwsCustomerActions.loadAwsCustomersFailed),
        tap(({ error }) => {
          if (error) {
            this.alertService.showErrorMessage("Error", error);
          }
        })
      );
    },
    { dispatch: false }
  );

  loadCustomers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AwsCustomerActions.loadAwsCustomers),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.customerSerivce
          .list(page, perPage, order, direction, filters, includes)
          .pipe(
            map((result) =>
              AwsCustomerActions.loadAwsCustomersCompleted({
                customers: result.data,
                currentPage: page,
                total: result.total,
                perPage,
                order,
                direction,
                filters,
                includes,
              })
            ),
            catchError((error) => {
              return of(AwsCustomerActions.loadAwsCustomersFailed({ error }));
            })
          );
      })
    );
  });

  changePage = createEffect(() => {
    return this.actions$.pipe(
      ofType(AwsCustomerActions.changePage),
      concatLatestFrom(() => this.store$.select(AwsCustomerSelectors.getAwsCustomersTableState)),
      map(
        ([
          { page, size },
          { total, currentPage, perPage, direction, order, filters, includes },
        ]) =>
          AwsCustomerActions.loadAwsCustomers({
            page: page,
            perPage: size,
            order,
            direction,
            filters,
            includes,
          })
      )
    );
  });

  changeSort = createEffect(() => {
    return this.actions$.pipe(
      ofType(AwsCustomerActions.changeSort),
      concatLatestFrom(() => this.store$.select(AwsCustomerSelectors.getAwsCustomersTableState)),
      map(
        ([
          action,
          { total, currentPage, perPage, direction, order, filters, includes },
        ]) =>
          AwsCustomerActions.loadAwsCustomers({
            page: currentPage,
            perPage: perPage,
            order: action.order,
            direction: action.direction,
            filters,
            includes,
          })
      )
    );
  });

  changeFilters = createEffect(() => {
    return this.actions$.pipe(
      ofType(AwsCustomerActions.changeFilters),
      concatLatestFrom(() => this.store$.select(AwsCustomerSelectors.getAwsCustomersTableState)),
      map(
        ([
          { filters },
          { total, currentPage, perPage, direction, order, includes },
        ]) =>
          AwsCustomerActions.loadAwsCustomers({
            page: currentPage,
            perPage: perPage,
            order,
            direction,
            filters,
            includes,
          })
      )
    );
  });

  edit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AwsCustomerActions.editCustomer),
      map(({ customer }) => {
        let dialogRef = this.dialog.open(AwsCustomerEditComponent, {
          data: {
            customer,
          },
        });
        return AwsCustomerActions.customerDialogOpened({ dialogId: dialogRef.id });
      })
    );
  });

  save$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AwsCustomerActions.saveCustomer),
      mergeMap(({ customer }) =>
        this.customerSerivce.upsert(customer).pipe(
          map((result) => AwsCustomerActions.saveCustomerCompleted({ customer: result })),
          catchError((error) => of(AwsCustomerActions.saveCustomerFailed({ error })))
        )
      )
    );
  });

  onSaveCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AwsCustomerActions.saveCustomerCompleted),
      tap(() =>
        this.alertService.showConfirmMessage(
          `AWS Customer saved successfully`,
        )
      ),
      map(() => AwsCustomerActions.closeCustomerDialog())
    );
  });

  closeDialog = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AwsCustomerActions.closeCustomerDialog),
        concatLatestFrom(() => this.store$.select(AwsCustomerSelectors.getAwsCustomerDialogId)),
        tap(([_, dialogId]) => {
          if (dialogId) {
            this.dialog.getDialogById(dialogId).close();
          }
        })
      );
    },
    { dispatch: false }
  );

  reloadAfterSave = createEffect(() => {
    return this.actions$.pipe(
      ofType(AwsCustomerActions.saveCustomerCompleted),
      concatLatestFrom(() => this.store$.select(AwsCustomerSelectors.getAwsCustomersTableState)),
      map(
        ([_, { currentPage, perPage, direction, order, filters, includes }]) =>
          AwsCustomerActions.loadAwsCustomers({
            page: currentPage,
            perPage,
            order,
            direction,
            filters,
            includes,
          })
      )
    );
  });


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private customerSerivce: LaravelAwsCustomerService,
    private dialog: MatDialog,
    private alertService: AlertService,
  ) { }
}
