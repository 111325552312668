import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { LegacyPageEvent as PageEvent, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { Request, RequestFilters } from 'src/app/commons/models/request.model';
import { BIG_PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';

export type RequestColumn = "id" | "user" | "type" | "hash" | "name" | "classification" | "false_positive" | "date_time" | "confidence" | "version" | "status" | "actions" | "preview" | "results";

@Component({
  selector: 'request-list',
  templateUrl: './request-list.component.html',
  styleUrls: ['./request-list.component.scss']
})
export class RequestListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  requests: Observable<Request[]>;

  @Input()
  defaultFilters: RequestFilters;

  pageSizeOptions = BIG_PAGE_SIZE_OPTIONS;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: RequestColumn[] = ["type", "name", "date_time", "classification", "false_positive", "actions"];

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<RequestFilters> = new EventEmitter<RequestFilters>();

  @Output()
  onSendRequest: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onReportRequest: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onShowRequest: EventEmitter<Request> = new EventEmitter<Request>();
  @Output()
  onReloadRequest: EventEmitter<Request> = new EventEmitter<Request>();
  @Output()
  onDeleteRequest: EventEmitter<Request> = new EventEmitter<Request>();
  @Output()
  onReprocessRequest: EventEmitter<Request> = new EventEmitter<Request>();
  // @Output()
  // onExportRequests: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  onFilter(filters: RequestFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }

  sendRequest() {
    this.onSendRequest.emit();
  }

  reportRequest() {
    this.onReportRequest.emit();
  }

  showRequest(request: Request) {
    this.onShowRequest.emit(request);
  }

  reloadRequest(request: Request) {
    this.onReloadRequest.emit(request);
  }

  deleteRequest(request: Request) {
    this.onDeleteRequest.emit(request);
  }

  reprocessRequest(request: Request) {
    this.onReprocessRequest.emit(request);
  }

  // exportRequests() {
  //   this.onExportRequests.emit();
  // }
}
