<identifai-aws-customer-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</identifai-aws-customer-filters>
<mat-card class="u-center-text" *ngIf="!(customers | async).length">
  <h4>No customer found</h4>
  <!-- <button *ngIf="canAdd" mat-raised-button (click)="addUser()">Add</button> -->
</mat-card>
<div [hidden]="!(customers | async).length" class="table-container">
  <table mat-table matSort [dataSource]="customers">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
      <td mat-cell *matCellDef="let customer">{{ customer.id }}</td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef>User</th>
      <td mat-cell *matCellDef="let customer">
        <div>{{ customer.userName }}</div>
        <div class="small">
          {{customer.user?.email}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="identifier">
      <th mat-header-cell *matHeaderCellDef>AWS Identifier</th>
      <td mat-cell *matCellDef="let customer">
        {{ customer.customerIdentifier }}
      </td>
    </ng-container>

    <ng-container matColumnDef="account_id">
      <th mat-header-cell *matHeaderCellDef>Aws Account ID</th>
      <td mat-cell *matCellDef="let customer">
        {{ customer.customerAwsAccountId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let customer">
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="select(customer)">
          Select
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="edit(row)"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>