import { Base, BaseDTO } from './base.model';
import { formatDateForBackend } from 'src/app/helpers/time.utils';

export interface ClassificationModelDTO extends BaseDTO {
  name: string;
  sku: string;
  description: string;
  deactivation_date: string;
}

export class ClassificationModel extends Base {
  name: string;
  sku: string;
  description: string;
  deactivationDate: Date;

  constructor(source: ClassificationModelDTO) {
    super(source);
    if (source) {
      this.name = source.name;
      this.sku = source.sku;
      this.description = source.description;
      this.deactivationDate = source.deactivation_date ? new Date(source.deactivation_date) : null;
    }
  }

  toDTO(): ClassificationModelDTO {
    let result: ClassificationModelDTO = <ClassificationModelDTO>super.toDTO();
    result.name = this.name;
    result.sku = this.sku;
    result.description = this.description;
    result.deactivation_date = this.deactivationDate ? formatDateForBackend(this.deactivationDate, true) : null;

    return result;
  }
}
