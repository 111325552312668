import { Action, createReducer, on } from '@ngrx/store';

import { AwsCustomerDTO, AwsCustomerFilters } from 'src/app/commons/models/aws_customer.model';
import * as AwsCustomerActions from '../actions/aws_customer.actions';

export interface AwsCustomerState {
  list: AwsCustomerDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: AwsCustomerFilters,
  dialogId: string,
  customer: AwsCustomerDTO,
};

const initialState: AwsCustomerState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  customer: null
};

const customerReducer = createReducer(
  initialState,
  on(AwsCustomerActions.loadAwsCustomersCompleted, (state, { customers, currentPage, total, perPage, order, direction, includes }): AwsCustomerState => {
    return { ...state, list: customers, currentPage, total, perPage, order, direction, includes };
  }),
  on(AwsCustomerActions.customerDialogOpened, (state, { dialogId }): AwsCustomerState => {
    return { ...state, dialogId };
  }),
  on(AwsCustomerActions.changeFilters, (state, { filters }): AwsCustomerState => {
    return { ...state, currentPage: 1, filters };
  }),
);

export function reducer(state: AwsCustomerState | undefined, action: Action) {
  return customerReducer(state, action);
}

