<header mat-dialog-title class="u-flex-full">
  <span>
    <span>New Classification</span>
  </span>
</header>

<mat-dialog-content>
  <p class="disclaimer">
    <b>Important: </b>the results presented herein are derived from probabilistic extractions and should not be
    construed as definitive.
    Probabilistic methods inherently include intrinsic errors; therefore, human oversight remains crucial to ensure
    accuracy and reliability.
  </p>
  <mat-form-field class="u-full-width">
    <mat-label>Type</mat-label>
    <mat-select [(ngModel)]="type">
      <mat-option value="image">Image</mat-option>
      <mat-option *can="'request_video'" value="video">Video</mat-option>
    </mat-select>
  </mat-form-field>
  <p *ngIf="type === 'image'">
    To achieve better classification, use JPG or JPEG images.
  </p>

  Upload from:
  <mat-button-toggle-group [(ngModel)]="selectedInputMode">
    <mat-button-toggle value="file" aria-label="Upload from file">
      FILE
    </mat-button-toggle>
    <mat-button-toggle value="url" aria-label="Upload from url">
      URL
    </mat-button-toggle>
  </mat-button-toggle-group>

  <form class="w-full">
    <mat-form-field *ngIf="selectedInputMode === 'file'" class="file-field w-full">
      <ngx-mat-file-input [formControl]="type === 'image' ? imageCtrl : videoCtrl" placeholder="Upload File"
        [accept]="acceptsString">
        <mat-icon ngxMatFileInputIcon color="primary" style="font-size: 1.5rem">folder</mat-icon>
      </ngx-mat-file-input>
      <mat-hint>Max size: {{ maxSize | byteFormat }} - Allowed extensions: {{acceptsString}}</mat-hint>
    </mat-form-field>
    <mat-form-field *ngIf="selectedInputMode === 'url'" class="w-full">
      <input matInput [formControl]="urlCtrl" type="url" placeholder="File URL" />
      <mat-hint>Max size: {{ maxSize | byteFormat }} - Allowed extensions:
        {{acceptsString}}</mat-hint>
    </mat-form-field>

    <mat-card>
      <mat-card-header>
        <mat-card-subtitle>Classification Options</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <ng-container>
          <div class="u-full-width">
            <ng-container *can="'request_llm'">
              <mat-checkbox class="u-full-width" *can="'request_llm'" [formControl]="withLlmCtrl">Describe with
                LLM</mat-checkbox>
            </ng-container>
            <mat-checkbox class="u-full-width" *can="'request_morphing'" [formControl]="withMorphingCtrl">Include
              morphing
              detection
              model</mat-checkbox>
            <ng-container *can="'request_video_keyframes'">
              <mat-checkbox *ngIf="type === 'video'" class="u-full-width" [formControl]="keyFramesController">Extract
                key
                frames</mat-checkbox>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *can="'request_video_credit_choice'">
          <mat-form-field class="u-full-width" *ngIf="type === 'video'">
            <mat-select [formControl]="framesCtrl"
              [placeholder]="keyFramesController.value ? 'Maximum number of frames' : 'Number of frames'">
              <mat-option [value]="5">Basic (5 credits)</mat-option>
              <mat-option [value]="10">Normal (10 credits)</mat-option>
              <mat-option [value]="30">High (30 credits)</mat-option>
              <ng-container *showFor="['admin']">
                <mat-option *ngIf="!!keyFramesController.value" [value]="9999999">Unlimited (admin
                  only)</mat-option>
              </ng-container>
            </mat-select>
            <mat-hint *ngIf="!keyFramesController.value">Select how many frames you want IdentifAI to analyze - 1
              credit
              for each frame</mat-hint>
            <mat-hint *ngIf="keyFramesController.value">Select maximum number of frames you want IdentifAI to
              analyze -
              1
              credit for each frame</mat-hint>
          </mat-form-field>
        </ng-container>
        <mat-form-field class="u-full-width" *ngIf="type === 'video' && !!keyFramesController.value">
          <mat-select [formControl]="keyFramesMethodCtrl" placeholder="Keyframes algorithm">
            <mat-option value="iframes">Default</mat-option>
            <mat-option value="color">Color</mat-option>
            <mat-option value="flow">Flow</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="primary" (click)="send()"
      [disabled]="selectedInputMode === 'file' ? (type === 'image' ? imageCtrl.invalid: videoCtrl.invalid) : urlCtrl.invalid">
      Submit
    </button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Close
  </button>
</mat-dialog-actions>
