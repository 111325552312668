import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AwsCustomerFilters } from 'src/app/commons/models/aws_customer.model';

@Component({
  selector: 'identifai-aws-customer-filters',
  templateUrl: './aws-customer-filters.component.html',
  styleUrls: ['./aws-customer-filters.component.scss'],
})
export class AwsCustomerFiltersComponent {

  @Output()
  onFilter: EventEmitter<AwsCustomerFilters> = new EventEmitter();

  private _defaultFilters: AwsCustomerFilters;
  filtersForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      search: "",
      user: ''
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getCustomerFilters());
  }

  private _getCustomerFilters(): AwsCustomerFilters {
    let filters: AwsCustomerFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
      filters.user = values.user;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
      user: null
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): AwsCustomerFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: AwsCustomerFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
          user: this.defaultFilters.user
        })
      }
    }
  }
}
