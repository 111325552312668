import { Component, Inject, OnChanges, OnDestroy, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { AwsCustomer, AwsCustomerDTO } from 'src/app/commons/models/aws_customer.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as AwsCustomerActions from 'src/app/store/actions/aws_customer.actions';
import { AppState } from 'src/app/store/reducers';

@Component({
  selector: "identifai-aws-customer-edit",
  templateUrl: "./aws-customer-edit.component.html",
  styleUrls: ["./aws-customer-edit.component.scss"],
})
export class AwsCustomerEditComponent implements OnInit, OnDestroy, OnChanges {
  private unsubscribe$ = new Subject<void>();
  private _customer: AwsCustomer;

  form: UntypedFormGroup;

  constructor(
    private store$: Store<AppState>,
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { customer: AwsCustomerDTO },
  ) {
    if (this.data?.customer) {
      this.customer = new AwsCustomer(this.data.customer);
    }
  }

  ngOnInit() {
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges() {
    if (this.form) {
      this.form.reset();
      if (this.customer) {
        this._initFormValues(this.customer);
      } else {
        this._initFormValues();
      }
    }
  }

  private _createForm() {
    let group = {
      user: ["", Validators.required],
      customerIdentifier: ["", Validators.required],
      customerAwsAccountId: ["", Validators.required],
      productCode: ["", Validators.required]
    };

    this.form = this.fb.group(group);
  }

  private _initFormValues(customer?: AwsCustomer) {
    if (customer) {
      this.form.patchValue({
        user: customer.user,
        customerIdentifier: customer.customerIdentifier,
        customerAwsAccountId: customer.customerAwsAccountId,
        productCode: customer.productCode
      });
    }
  }

  get customer(): AwsCustomer {
    return this._customer;
  }

  set customer(customer: AwsCustomer) {
    this._customer = customer;
    this.ngOnChanges();
  }

  private _prepareSaveAwsCustomer(): AwsCustomer {
    let savingAwsCustomer: AwsCustomer = AwsCustomer.fromFormGroup(this.form, this.customer);
    return savingAwsCustomer;
  }

  save() {
    let unsavedEntity = this._prepareSaveAwsCustomer();
    this.store$.dispatch(AwsCustomerActions.saveCustomer({ customer: unsavedEntity.toDTO() }));
  }

  close() {
    if (this.form.pristine) {
      this.store$.dispatch(AwsCustomerActions.closeCustomerDialog());
    } else {
      this.alertService
        .showConfirmDialog(
          "Close",
          "There are unsaved changes. Are you sure you want to close?"
        )
        .subscribe((result) => {
          if (result) {
            this.store$.dispatch(AwsCustomerActions.closeCustomerDialog());
          }
        });
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
