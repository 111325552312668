import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { toFormData } from 'src/app/helpers/form.utils';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { environment } from 'src/environments/environment';

import { ApiKeyLogDTO } from '../../models/api-key-log.model';
import { UserDTO, UsersGraphData } from '../../models/user.model';
import { UserFilters } from './../../models/user.model';

export interface LoginSuccessDTO {
  // success_token: string;
  user: UserDTO;
}

@Injectable({
  providedIn: "root"
})
export class LaravelUserService {


  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      getCurrentUser: environment.baseUrl + "/api/current_user",
      login: environment.baseUrl + "/api/login",
      register: environment.baseUrl + "/api/register",
      logout: environment.baseUrl + "/api/logout",
      forgotPassword: environment.baseUrl + "/api/forgot_password",
      resetPassword: environment.baseUrl + "/api/reset_password",
      sendCreationEmail: environment.baseUrl + "/api/send_creation_email",
      verifyEmail: environment.baseUrl + "/api/verify_email",
      list: environment.baseUrl + "/api/users",
      show: environment.baseUrl + "/api/user",
      store: environment.baseUrl + "/api/user",
      destroy: environment.baseUrl + "/api/user",
      changePassword: environment.baseUrl + "/api/user/change_password",
      resetApiKey: environment.baseUrl + "/api/user/apikey_reset",
      loadApiKeys: environment.baseUrl + "/api/user/apikeys",
      graph: environment.baseUrl + "/api/users/graph",
      heuristics: environment.baseUrl + "/api/heuristics",
      heuristic: environment.baseUrl + "/api/heuristic",
      update_show_tutorial: environment.baseUrl + "/api/update_show_tutorial",
    };
  }

  public list(page?: number,
    per_page?: number,
    order?: string,
    direction?: string,
    filters?: UserFilters,
    includes?: string[]
  ): Observable<ListResultDTO<UserDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (includes) params["includes[]"] = includes;
    if (filters) {
      if (filters.search) params["search"] = "" + filters.search;
      if (filters.roles) params["roles[]"] = filters.roles;
    }
    if (per_page) {
      params["per_page"] = "" + per_page;
      if (page) params["page"] = "" + page;
      return this.httpClient.get<ListResultDTO<UserDTO>>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      });
    } else {
      return this.httpClient.get<UserDTO[]>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      }).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }

  public getCurrentUser(): Observable<UserDTO> {
    return this.httpClient.get<UserDTO>(this.ROUTES.getCurrentUser);
  }

  public loadHeuristics(): Observable<string[]> {
    return this.httpClient.get<string[]>(this.ROUTES.heuristics);
  }

  public saveHeuristic(userId: number, heuristic: string): Observable<UserDTO> {
    return this.httpClient.put<UserDTO>(`${this.ROUTES.heuristic}/${userId}`, { heuristic: heuristic });
  }

  public getUserById(id: number): Observable<UserDTO> {
    let params = { id: "" + id };
    return this.httpClient.get<UserDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });

  }

  public upsert(user: UserDTO): Observable<UserDTO> {
    if (user.id) {
      return this.httpClient.post<UserDTO>(this.ROUTES.store,
        toFormData(user, 'PUT')
      );
    } else {
      return this.httpClient.post<UserDTO>(this.ROUTES.store,
        toFormData(user)
      );
    }
  }

  public login(email: string, password: string): Observable<LoginSuccessDTO> {
    let params = {
      email: email,
      password: password
    };
    return this.httpClient.post<LoginSuccessDTO>(this.ROUTES.login, params);
  }

  public register(name: string, email: string, password: string, tos: boolean): Observable<void> {
    let params = {
      name,
      email,
      password,
      tos
    };
    return this.httpClient.post<void>(this.ROUTES.register, params);
  }

  public logout(): Observable<any> {
    return this.httpClient.post<any>(this.ROUTES.logout, {});
  }

  public delete(id: number): Observable<any> {
    let params = { id: "" + id };
    return this.httpClient.delete(this.ROUTES.destroy, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public changePassword(id: number, newPassword: string): Observable<UserDTO> {
    let params = {
      id: "" + id,
      new_password: newPassword
    };
    return this.httpClient.put<UserDTO>(this.ROUTES.changePassword, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public forgotPassword(email: string): Observable<void> {
    return this.httpClient.post<void>(this.ROUTES.forgotPassword, {
      email
    });
  }

  public resetPassword(token: string, email: string, password: string, password_confirmation: string): Observable<void> {
    return this.httpClient.post<void>(this.ROUTES.resetPassword, {
      token,
      email,
      password,
      password_confirmation
    });
  }

  public resetApiKey(userId: number): Observable<UserDTO> {
    return this.httpClient.post<UserDTO>(this.ROUTES.resetApiKey, {
      id: userId
    });
  }

  public loadApiKeys(userId: number): Observable<ApiKeyLogDTO[]> {
    return this.httpClient.get<ApiKeyLogDTO[]>(this.ROUTES.loadApiKeys, {
      params: new HttpParams({ fromObject: { id: userId } })
    });
  }

  public verifyEmail(token: string): Observable<void> {
    return this.httpClient.post<void>(this.ROUTES.verifyEmail, {
      token
    });
  }

  public graph(): Observable<UsersGraphData> {
    return this.httpClient.get<UsersGraphData>(this.ROUTES.graph);
  }

  public sendCreationEmail(userId: number): Observable<UserDTO> {
    return this.httpClient.post<UserDTO>(this.ROUTES.sendCreationEmail, {
      id: userId
    });
  }

  public updateShowTutorial(userId: number, show_tutorial: boolean): Observable<UserDTO> {
    return this.httpClient.patch<UserDTO>(this.ROUTES.update_show_tutorial, {
      id: userId,
      show_tutorial
    });
  }
}
