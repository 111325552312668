import { UntypedFormGroup } from "@angular/forms";

import { Base, BaseDTO } from "./base.model";
import { User, UserDTO } from "./user.model";

export interface AwsCustomerFilters {
  search?: string;
  user?: UserDTO;
}

export interface AwsCustomerDTO extends BaseDTO {
  user_id: number;
  user: UserDTO;
  customer_identifier: number;
  customer_aws_account_id: string;
  product_code: string;
}

export class AwsCustomer extends Base {
  userId: number;
  user: User;
  customerIdentifier: number;
  customerAwsAccountId: string;
  productCode: string;

  constructor(source: AwsCustomerDTO) {
    super(source);
    if (source) {
      this.userId = source.user_id;
      this.user = new User(source.user);
      this.customerIdentifier = source.customer_identifier;
      this.customerAwsAccountId = source.customer_aws_account_id;
      this.productCode = source.product_code;

    }
  }

  toDTO(): AwsCustomerDTO {
    let result: AwsCustomerDTO = <AwsCustomerDTO>super.toDTO();
    result.user_id = this.userId;
    if (this.user) {
      result.user = this.user.toDTO();
      result.user_id = this.user.id;
    }
    result.customer_identifier = this.customerIdentifier;
    result.customer_aws_account_id = this.customerAwsAccountId;
    result.product_code = this.productCode;
    return result;
  }

  static fromFormGroup(formGroup: UntypedFormGroup, original?: AwsCustomer) {
    const formModel = formGroup.getRawValue();
    let entity: AwsCustomer = new AwsCustomer(null);
    entity.userId = formModel.user_id ? formModel.user_id : formModel?.user?.id;
    entity.user = formModel.user;
    entity.customerIdentifier = formModel.customerIdentifier;
    entity.customerAwsAccountId = formModel.customerAwsAccountId;
    entity.productCode = formModel.productCode;
    entity.id = original ? original.id : null;

    return entity;
  }

  get userName(): string {
    return this.user?.name;
  }
}
