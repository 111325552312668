import { ChartDataset, ChartOptions } from 'chart.js';


export const GRAPH_PALETTE = ["#212c4a", "#394563", "#505d7b", "#677592", "#7e8daa", "#94a4c1", "#abbcd9", "#c2d4f0"]

export type GraphSerie = {
  name: string,
  value: number
};
export type GraphMultiSeries = {
  name: string,
  series: GraphSerie[]
};

export type GraphData = {
  labels: string[],
  datasets: ChartDataset[]
}

export const DEFAULT_OPTIONS: ChartOptions = {
  plugins: {
    legend: {
      position: 'right',
      onClick: function (e, legendItem) {
        const index = legendItem.datasetIndex;
        const ci = this.chart;
        const alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;

        ci.data.datasets.forEach(function (e, i) {
          const meta = ci.getDatasetMeta(i);

          if (i !== index) {
            if (!alreadyHidden) {
              meta.hidden = meta.hidden === null ? !meta.hidden : null;
            } else if (meta.hidden === null) {
              meta.hidden = true;
            }
          } else if (i === index) {
            meta.hidden = null;
          }
        });

        ci.update();
      },
    }
  },
  scales: {
    x: {
      ticks:
      {
        callback: function (value) {
          const label = this.getLabelForValue(Number(value));
          if (label.length > 10)
            return label.substring(0, 10) + '...';
          return label;
        }
      },
      stacked: true,
      title: {
        display: true,
        text: "Model"
      }
    },
    y: {
      stacked: true,
      max: 100,
      min: 0,
      title: {
        display: true,
        text: "Confidence %"
      }
    }
  }
}

export function deepMerge(obj1: any, obj2: any): any {
  const output = Object.assign({}, obj1);

  for (const key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      if (typeof obj2[key] === 'object' && obj2[key] !== null && obj1[key]) {
        output[key] = deepMerge(obj1[key], obj2[key]);
      } else {
        output[key] = obj2[key];
      }
    }
  }

  return output;
}

export function mapDataForGraph(allData: GraphMultiSeries[]): GraphData {
  const labels = allData.map(data => data.name);
  const datasets = [];

  const sets: Set<string> = new Set<string>();
  allData.map(data => {
    data.series.forEach((serie) => {
      sets.add(serie.name);
    })
  });

  allData.forEach(data => {
    Array.from(sets).forEach((setName, index) => {
      const value = data.series.find(serie => serie.name === setName)?.value || 0;
      let dataset: ChartDataset = datasets.find(d => d.label === setName);
      if (!dataset) {
        const paletteIndex = index % 2 === 0 ? index % GRAPH_PALETTE.length : Math.abs(GRAPH_PALETTE.length - index) % GRAPH_PALETTE.length;
        dataset = {
          data: [value],
          label: setName,
          backgroundColor: GRAPH_PALETTE[paletteIndex],
          borderColor: GRAPH_PALETTE[paletteIndex],
          pointBorderColor: GRAPH_PALETTE[paletteIndex],
          pointBackgroundColor: GRAPH_PALETTE[paletteIndex],
        }
        datasets.push(dataset);
      } else {
        dataset.data.push(value || 0);
      }
    })

  });

  return { labels, datasets };
}


export const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '5px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    //tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .5s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(b => b.lines);

    const tableHead = document.createElement('thead');

    titleLines.forEach(title => {
      const tr = document.createElement('tr');
      // tr.style.borderWidth = 0;

      const th = document.createElement('th');
      // th.style.borderWidth = 0;
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement('span');
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = '2px';
      span.style.marginRight = '10px';
      span.style.height = '10px';
      span.style.width = '10px';
      span.style.display = 'inline-block';

      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      // tr.style.borderWidth = 0;

      const td = document.createElement('td');
      // td.style.borderWidth = 0;

      const text = document.createTextNode(body);

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};
