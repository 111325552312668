<div style="height: auto; display:flex; flex-direction: column;">
  <!-- <mat-button-toggle-group [(ngModel)]="classification">
    <mat-button-toggle value="human">Human</mat-button-toggle>
    <mat-button-toggle value="artificial">Artificial</mat-button-toggle>
  </mat-button-toggle-group> -->
  <!-- <identifai-multi-series-graph type="bar" [data]="graphData" [config]="graphConfig"></identifai-multi-series-graph> -->
  <!-- <ngx-charts-bar-vertical-stacked [results]="data" [legend]="true" [showXAxisLabel]="chartConfig.showXAxisLabel"
    [showYAxisLabel]="chartConfig.showYAxisLabel" [xAxis]="chartConfig.xAxis" [yAxis]="chartConfig.yAxis"
    [xAxisLabel]="chartConfig.xAxisLabel" [yAxisLabel]="chartConfig.yAxisLabel" [scheme]="chartConfig.scheme">
  </ngx-charts-bar-vertical-stacked> -->
  <mat-checkbox *ngIf="excludedModels?.length" [formControl]="showExcludedModelsCtrl">Show excluded
    models: {{excludedModels.join(', ')}}</mat-checkbox>
  <div class="gauges">
    <mat-card *ngFor="let result of availableResults" class="gauge">
      <h3 style="text-align: center;">{{result.model}}</h3>
      <ngx-gauge [size]="150" type="semi" cap="round" [label]="verdict" [value]="rightResult(result)" [thick]="12"
        append="%" foregroundColor="#212C4A">
      </ngx-gauge>
      <span style="text-align: right;">

        <mat-icon *ngIf="!!modelDescription(result.model)" [matTooltip]="modelDescription(result.model)">info</mat-icon>
      </span>
    </mat-card>
  </div>


</div>