import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Actions, EffectsModule, ofType } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { Action, Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgChartsModule } from 'ng2-charts';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, POSITION, SPINNER } from 'ngx-ui-loader';
import { take } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CredentialsInterceptorService } from './helpers/credentials-interceptor.service';
import { MaterialModule } from './modules/material/material.module';
import { SharedModule } from './modules/shared/shared.module';
import * as AuthActions from './store/actions/auth.actions';
import * as ConfigActions from './store/actions/config.actions';
import { AuthEffects } from './store/effects/auth.effects';
import { RouterEffects } from './store/effects/router.effects';
import { UserEffects } from './store/effects/user.effects';
import { LoggerEffects } from './store/effects/logger.effects';
import { AppState, metaReducers, reducers } from './store/reducers';
import { PricingEffects } from './store/effects/pricing.effects';
import { forkJoin, Observable } from 'rxjs';
import { ConfigEffets } from './store/effects/config.effects';
import { AwsCustomerEffects } from './store/effects/aws_customer.effects';

// import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
// import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
registerLocaleData(localeIt);

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: "#EE0025",
  fgsType: SPINNER.squareJellyBox,
  hasProgressBar: false,
  overlayColor: "rgba(130,130,130,0.7)",
  fgsPosition: POSITION.centerCenter,
  delay: 300,
  minTime: 10,
  fastFadeOut: true
};
export function initApplication(
  store: Store<AppState>,
  actions$: Actions
): Function {
  return () =>
    new Promise((resolve) => {
      store.dispatch(AuthActions.loadCurrentUser());
      store.dispatch(ConfigActions.loadAvailableModesl());
      const initTasks$: Observable<Action>[] = [];
      initTasks$.push(
        actions$.pipe(ofType(
          AuthActions.loadCurrentUserCompleted,
          AuthActions.loadCurrentUserFailed
        ), take(1))
      );
      initTasks$.push(
        actions$.pipe(ofType(
          ConfigActions.loadAvailableModeslCompleted,
          ConfigActions.loadAvailableModeslFailed,
        ), take(1))
      );
      forkJoin(initTasks$).subscribe(() => resolve(true));
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    // MatInputModule,
    // MatFormFieldModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
    }),
    MaterialModule,
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: environment.production,
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([RouterEffects, AuthEffects, UserEffects, LoggerEffects, PricingEffects, ConfigEffets, AwsCustomerEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    NgChartsModule,
  ],
  providers: [
    // AuthEffects,
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      deps: [Store, Actions],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CredentialsInterceptorService,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: "it-IT",
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
