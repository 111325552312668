<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="customer">Edit AWS customer data</span>
    <span *ngIf="!customer">New AWS customer</span>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="form" novalidate>
    <mat-form-field class="u-full-width">
      <identifai-user-field formControlName="user" placeholder="User"></identifai-user-field>
      <mat-error>Field required</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="AWS Customer identifier" formControlName="customerIdentifier" type="text" required />
      <mat-error>Field required</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="AWS Account ID" formControlName="customerAwsAccountId" type="text" required />
      <mat-error>Field required</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Product code" formControlName="productCode" type="text" required />
      <mat-error>Field required</mat-error>
    </mat-form-field>
  </form>
  <!-- {{form.value | json}} -->
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()" [disabled]="form.pristine">
      Reset
    </button>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="form.pristine || !form.valid">
      Save
    </button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Close
  </button>
</mat-dialog-actions>