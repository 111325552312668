import { Component } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil, map } from 'rxjs';
import { ClassificationOptions } from 'src/app/commons/models/image.model';
import { RequestType, RequestInputMode } from 'src/app/commons/models/request.model';
import {
  IMG_MAX_SIZE,
  IMG_VALID_EXTENSIONS,
  VIDEO_MAX_SIZE,
  VIDEO_VALID_EXTENSIONS,
} from 'src/app/constants/media.constants';
import { fileExtensionValidator, fileSizeValidator } from 'src/app/helpers/validators/file.validator';
import * as RequestActions from 'src/app/store/actions/request.actions';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';
import { AppState } from 'src/app/store/reducers';
import { User } from 'src/app/commons/models/user.model';

@Component({
  selector: "identifai-request-send",
  templateUrl: "./request-send.component.html",
  styleUrls: ["./request-send.component.scss"],
})
export class RequestSendComponent {
  private unsubscribe$ = new Subject<void>();

  imageCtrl: UntypedFormControl = new UntypedFormControl(null, [
    Validators.required, fileExtensionValidator(IMG_VALID_EXTENSIONS), fileSizeValidator(IMG_MAX_SIZE)
  ]);

  videoCtrl: UntypedFormControl = new UntypedFormControl(null, [
    Validators.required, fileExtensionValidator(VIDEO_VALID_EXTENSIONS), fileSizeValidator(VIDEO_MAX_SIZE)
  ]);

  framesCtrl: FormControl<number> = new FormControl(5, [
    Validators.required
  ]);

  urlCtrl = new FormControl("", [
    Validators.required
  ]);

  withLlmCtrl = new FormControl(false);
  withMorphingCtrl = new FormControl(true);
  keyFramesController = new FormControl(false);
  keyFramesMethodCtrl: FormControl<string> = new FormControl('iframes')

  type: RequestType = "image";
  selectedInputMode: RequestInputMode = "file";

  // maxSize = MAX_SIZE;
  // acceptsString: string = VALID_EXTENSIONS.map((v) => `.${v}`).join(',')

  constructor(
    private store$: Store<AppState>,
  ) {
    store$.pipe(select(AuthSelectors.getCurrentUser), takeUntil(this.unsubscribe$), map(dto => dto ? new User(dto) : null), map(user => user.admin)).subscribe(admin => {
      this.keyFramesController.setValue(admin);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  send() {
    const options: ClassificationOptions = {
      with_llm: this.withLlmCtrl.value,
      with_morphing: this.withMorphingCtrl.value,
      key_frames: this.keyFramesController.value,
      key_frames_method: this.keyFramesMethodCtrl.value
    }

    if (this.selectedInputMode === "file") {
      let image = this.imageCtrl.value;
      let video = this.videoCtrl.value;
      const file = this.type === "image" ? image : video;
      this.store$.dispatch(RequestActions.sendRequest({ file, requestType: this.type, options, frames: this.framesCtrl.value }));
    } else if (this.selectedInputMode === "url") {
      let url = this.urlCtrl.value;
      this.store$.dispatch(RequestActions.sendRequestFromUrl({ url: this.urlCtrl.value, requestType: this.type, options, frames: this.framesCtrl.value }));
    }
  }

  get maxSize(): number {
    return this.type === "image" ? IMG_MAX_SIZE : VIDEO_MAX_SIZE;
  }

  get acceptsString(): string {
    const EXTS = this.type === "image" ? IMG_VALID_EXTENSIONS : VIDEO_VALID_EXTENSIONS;
    return EXTS.map((v) => `.${v}`).join(',')
  }

  close() {
    this.store$.dispatch(RequestActions.closeRequestDialog());
  }
}
