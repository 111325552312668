import { ApplicationInitStatus, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { ChartOptions, plugins } from 'chart.js';
import { callback } from 'chart.js/dist/helpers/helpers.core';
import { map } from 'rxjs';
import { ClassificationModel } from 'src/app/commons/models/classification_model.model';
import { ClassificationResult, ClassificationType } from 'src/app/commons/models/request.model';
import { deepMerge, DEFAULT_OPTIONS, externalTooltipHandler, GraphData, mapDataForGraph } from 'src/app/helpers/graph.utils';
import { AppState } from 'src/app/store/reducers';
import * as ConfigSelectors from 'src/app/store/selectors/config.selectors';

@Component({
  selector: 'identifai-image-models',
  templateUrl: './image-models.component.html',
  styleUrls: ['./image-models.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageModelsComponent implements OnChanges {

  @Input()
  results: ClassificationResult[];

  @Input()
  verdict: ClassificationType = 'human';

  @Input()
  excludedModels: string[] = [];

  models: ClassificationModel[] = [];

  showExcludedModelsCtrl: FormControl = new FormControl(false);

  // chartConfig = ResultsBarGraph;

  graphData: GraphData;

  graphConfig: ChartOptions = deepMerge(DEFAULT_OPTIONS, {
    scales: {
      x: {
        title: {
          display: true,
          text: "Model"
        }
      },
      y: {
        max: 100,
        min: 0,
      }
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: externalTooltipHandler,
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        displayColors: true,
        callbacks: {
          title: (tooltipItems) => {
            const modelName = tooltipItems[0].label;
            const modelDescription = this.modelDescription(modelName);
            return modelDescription || modelName
          },

          label: (tooltipItem) => {
            const value = Number(tooltipItem.raw).toFixed(2);
            return `${value}%`;
          }
        }
      }
    }
  });



  constructor(private $store: Store<AppState>) {
    this.$store.pipe(select(ConfigSelectors.models), map(dtos => dtos ? dtos.map(dto => new ClassificationModel(dto)) : null)).subscribe(models => {
      this.models = models;
    })
    this.showExcludedModelsCtrl.valueChanges.subscribe(() => {
      this._refreshGraphData();
    })
  }

  modelDescription(name: string): string {
    return this.models.find(m => m.name === name)?.description;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._refreshGraphData();
  }

  get availableResults(): ClassificationResult[] {
    return this.results.filter(r => this.showExcludedModelsCtrl.value || !this.excludedModels.includes(r.model));
  }

  rightResult(result: ClassificationResult): number {
    const right = result.classification.find(c => c.label === this.verdict);
    return Math.round(right.score * 100 * 100) / 100;
  }

  private _refreshGraphData() {
    const data = this.results.filter(r => this.showExcludedModelsCtrl.value || !this.excludedModels.includes(r.model)).map(result => {
      const name = result.model;
      const right = result.classification.find(c => c.label === this.verdict);
      const wrong = result.classification.find(c => c.label !== this.verdict);

      return {
        name, series: [{ name: right.label, value: right.score * 100 }, { name: wrong.label, value: wrong.score * 100 }]
      }
    });

    this.graphData = mapDataForGraph(data);
  }

}
