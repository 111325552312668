import { createSelector } from '@ngrx/store';

import { selectConfigState } from '../reducers';
import { ConfigState } from '../reducers/config.reducer';
import { ClassificationModelDTO } from 'src/app/commons/models/classification_model.model';

export const models = createSelector(
  selectConfigState,
  (state: ConfigState) => state.models
);

export const activeModels = createSelector(
  models,
  (models: ClassificationModelDTO[]) => models?.filter(m => !m.deactivation_date)
);
