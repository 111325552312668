<header mat-dialog-title class="u-flex-full">
  <span> Tutorial </span>
</header>
<mat-dialog-content>
  <div class="u-full-width">
    <video *ngIf="tutorialUrl" [src]="tutorialUrl" width="100%" height="100%" controls></video>
    <mat-checkbox [formControl]="stopCtrl">Do not show automatically</mat-checkbox>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Close
  </button>
</mat-dialog-actions>