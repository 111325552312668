import { Action, createReducer, on } from '@ngrx/store';

import * as ConfigActions from '../actions/config.actions';
import { ClassificationModelDTO } from 'src/app/commons/models/classification_model.model';

export interface ConfigState {
  models: ClassificationModelDTO[]
};

const initialState: ConfigState = {
  models: [],
};

const configReducer = createReducer(
  initialState,
  on(ConfigActions.loadAvailableModeslCompleted, (state, { models }): ConfigState => {
    return { ...state, models };
  })
);

export function reducer(state: ConfigState | undefined, action: Action) {
  return configReducer(state, action);
}

