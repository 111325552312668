import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { BIG_PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import { UserFilters } from 'src/app/commons/models/user.model';
import { AwsCustomer, AwsCustomerFilters } from 'src/app/commons/models/aws_customer.model';

export type AwsCustomersColumn = "id" | "user" | "identifier" | "account_id" | "actions";

@Component({
  selector: 'aws-customer-list',
  templateUrl: './aws-customer-list.component.html',
  styleUrls: ['./aws-customer-list.component.scss']
})
export class AwsCustomerListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  customers: Observable<AwsCustomer[]>;

  @Input()
  defaultFilters: UserFilters;

  pageSizeOptions = BIG_PAGE_SIZE_OPTIONS;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: AwsCustomersColumn[] = ["id", "user", "identifier", "account_id"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<UserFilters> = new EventEmitter<UserFilters>();

  @Output()
  onSelect: EventEmitter<AwsCustomer> = new EventEmitter<AwsCustomer>();
  @Output()
  onAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEdit: EventEmitter<AwsCustomer> = new EventEmitter<AwsCustomer>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  add() {
    this.onAdd.emit();
  }

  edit(customer: AwsCustomer) {
    this.onEdit.emit(customer);
  }

  select(customer: AwsCustomer) {
    this.onSelect.emit(customer);
  }

  onFilter(filters: AwsCustomerFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}
