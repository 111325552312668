import { Component, Inject, OnChanges, OnDestroy, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { select, Store, } from '@ngrx/store';
import { map, Subject, takeUntil } from 'rxjs';
import { PERIODS, Pricing } from 'src/app/commons/models/pricing.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as PricingActions from 'src/app/store/actions/pricing.actions';
import * as ConfigSelectors from 'src/app/store/selectors/config.selectors';
import { AppState } from 'src/app/store/reducers';

@Component({
  selector: "identifai-pricing-edit",
  templateUrl: "./pricing-edit.component.html",
  styleUrls: ["./pricing-edit.component.scss"],
})
export class PricingEditComponent implements OnInit, OnDestroy, OnChanges {
  private unsubscribe$ = new Subject<void>();
  private _pricing: Pricing;

  pricingForm: UntypedFormGroup;

  periods = PERIODS;
  models: string[] = [];

  constructor(
    private store$: Store<AppState>,
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { pricing: Pricing, currentPricing?: boolean },
  ) {
    if (this.data) {
      this.pricing = this.data.pricing;
    }
    this.store$.pipe(select(ConfigSelectors.activeModels), takeUntil(this.unsubscribe$), map(models => models.map(m => m.name))).subscribe(models => { this.models = models });
  }

  ngOnInit() {
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges() {
    if (this.pricingForm) {
      this.pricingForm.reset();
      if (this.pricing) {
        this._initFormValues(this.pricing);
      } else {
        this._initFormValues();
      }
    }
  }

  private _createForm() {

    let group = {
      name: ["", Validators.required],
      sku: ["", Validators.required],
      details: this.fb.group({
        price: [""],
        credits: [""],
        discount: [0],
        period: [""],
        request_options: this.fb.group({
          models: [""],
          morphing: [false],
          llm: [false],
          video: [false],
          video_keyframes: [false],
          video_credit_choice: [false]
        }),
        response_options: this.fb.group({
          model_details: [false],
          heuristics: [false],
          telemetry: [false],
          bias_detection: [false],
        })
      })
    };

    this.pricingForm = this.fb.group(group);
  }

  private _initFormValues(pricing?: Pricing) {
    if (pricing) {
      this.pricingForm.patchValue({
        name: pricing.name,
        sku: pricing.sku,
        details: pricing.details
      });
    }
  }

  get pricing(): Pricing {
    return this._pricing;
  }

  set pricing(pricing: Pricing) {
    this._pricing = pricing;
    this.ngOnChanges();
  }

  private _prepareSavePricing(): Pricing {
    let savingPricing: Pricing = Pricing.fromFormGroup(this.pricingForm, this.pricing);
    return savingPricing;
  }

  save() {
    let unsavedEntity = this._prepareSavePricing();
    this.store$.dispatch(PricingActions.savePricing({ pricing: unsavedEntity }));
  }

  close() {
    if (this.pricingForm.pristine) {
      this.store$.dispatch(PricingActions.closePricingDialog());
    } else {
      this.alertService
        .showConfirmDialog(
          "Close",
          "There are unsaved changes. Are you sure you want to close?"
        )
        .subscribe((result) => {
          if (result) {
            this.store$.dispatch(PricingActions.closePricingDialog());
          }
        });
    }
  }

  deletePricing() {
    if (this.pricing) {
      this.store$.dispatch(PricingActions.deletePricing({ pricing: this.pricing.toDTO() }));
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
