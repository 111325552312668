<!-- <div class="export-btn-container">
  <button *ngIf="(requests | async).length" mat-raised-button color="primary" (click)="exportRequests()">
    Export Classifications
  </button>
</div> -->

<request-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</request-filters>
<mat-card class="u-center-text" *ngIf="!(requests | async).length">
  <h4>No classification found</h4>
  <div class="button-bar">
    <button mat-raised-button color="primary" (click)="sendRequest()">
      Classify an image/video
    </button>
  </div>
</mat-card>
<div [hidden]="!(requests | async).length" class="table-container">
  <table mat-table matSort [dataSource]="requests">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
      <td mat-cell *matCellDef="let request">{{ request.id }}</td>
    </ng-container>

    <ng-container matColumnDef="hash">
      <th mat-header-cell *matHeaderCellDef>Hash</th>
      <td mat-cell *matCellDef="let request">
        {{ request?.imageHash || request?.videoHash }}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let request">
        {{ request?.type | file_type }}
        <span class="features">
          <span *ngIf="request.type == 'video' && request.video.imagesCount > 0"
            class="badge badge--primary badge--small" [matTooltip]="request.video.imagesCount + ' frames analyzed'">
            {{request.video.imagesCount}}
          </span>
          <span class="badge badge--primary badge--small" *ngIf=" request?.path" matTooltip="Preview is available">
            Preview
          </span>
          <ng-container *ngIf="request.classificationOptions as options">
            <span class="badge  badge--small" *ngIf="options.key_frames"
              [matTooltip]="'Key frames extracted with method ' + options.key_frames_method">Key Frames</span>
            <span class="badge badge--small" *ngIf="options.with_llm" matTooltip="Describe with LLM">LLM</span>
            <span class="badge badge--small" *ngIf="options.with_morphing"
              matTooltip="Include morphing detection model">Morphing</span>
          </ng-container>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="preview">
      <th mat-header-cell *matHeaderCellDef>Preview</th>
      <td mat-cell *matCellDef="let request">
        <span class="badge badge--primary" *ngIf="request?.path">
          Yes
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let request">
        <div>
          {{ request?.name }} <span class="badge badge--primary badge--small" matTooltip="Image has a C2PA Manifest"
            *ngIf="!!request?.image?.details">C2PA Manifest</span>
        </div>
        <div *showFor="['admin']" class="small">
          Author: {{request.user?.name}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef>User</th>
      <td mat-cell *matCellDef="let request">
        {{ request?.user?.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="classification">
      <th mat-header-cell *matHeaderCellDef>Verdict</th>
      <td mat-cell *matCellDef="let request">
        <ng-container *ngIf="!!request?.classification">
          <div class="no_break">
            Possibile {{ request?.classification | classification }}
          </div>
          <div class="small">
            {{ request?.confidence | percent }}
            <span class="badge badge--primary" *ngIf="request?.falsePositive">Reported</span>
          </div>
        </ng-container>
        <div *ngIf="!request?.classification">
          Analyzing...
        </div>
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="results">
      <th mat-header-cell *matHeaderCellDef>Results</th>
      <td mat-cell *matCellDef="let request">
        {{ request?.image?.results | json }}
      </td>
    </ng-container> -->

    <ng-container matColumnDef="false_positive">
      <th mat-header-cell *matHeaderCellDef>Reported</th>
      <td mat-cell *matCellDef="let request">
        <span class="badge badge--primary" *ngIf="request?.falsePositive">Reported</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="confidence">
      <th mat-header-cell *matHeaderCellDef>Confidence</th>
      <td mat-cell *matCellDef="let request">
        {{ request?.confidence | percent }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date_time">
      <th mat-header-cell *matHeaderCellDef>Date & Time</th>
      <td mat-cell *matCellDef="let request">
        <span class="no_break">{{ request.createdAt | date : "short" }}</span>
      </td>
    </ng-container>
    <!--
    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef>Version</th>
      <td mat-cell *matCellDef="let request">
        {{ request?.image?.version }}
      </td>
    </ng-container> -->

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let request">
        {{ request?.classification ? "Completed" : "Analyzing..." }}

      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef="let request">
      </th>
      <td mat-cell *matCellDef="let request">
        <button *ngIf="!request?.classification" mat-icon-button color="primary" matTooltip="Refresh"
          (click)="$event.stopPropagation(); reloadRequest(request)">
          <mat-icon>refresh</mat-icon>
        </button>
        <ng-container *showFor="['admin']">
          <button *ngIf="request?.classification" mat-icon-button color="primary" matTooltip="Reprocess"
            (click)="$event.stopPropagation(); reprocessRequest(request)">
            <mat-icon>update</mat-icon>
          </button>
          <button mat-icon-button color="primary" matTooltip="Delete"
            (click)="$event.stopPropagation(); deleteRequest(request)">
            <mat-icon>delete</mat-icon>
          </button>
        </ng-container>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="showRequest(row)"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>