import { createAction, props } from '@ngrx/store';

import { AwsCustomerDTO, AwsCustomerFilters } from 'src/app/commons/models/aws_customer.model';
import { User } from '../../commons/models/user.model';

export const loadAwsCustomers = createAction('[Aws Customers] Load customers', props<{ page?: number, perPage?: number, order?: string, direction?: string, filters?: AwsCustomerFilters, includes?: string[] }>());
export const loadAwsCustomersCompleted = createAction('[Aws Customers] Load customers Completed', props<{ customers: AwsCustomerDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: AwsCustomerFilters, includes?: string[] }>());
export const loadAwsCustomersFailed = createAction('[Aws Customers] Load customers Failed', props<{ error: any }>());

export const changePage = createAction('[Aws Customers] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Aws Customers] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Aws Customers] Change filters', props<{ filters: AwsCustomerFilters }>());

export const editCustomer = createAction('[Aws Customers] Edit customer', props<{ customer?: AwsCustomerDTO }>());
export const customerDialogOpened = createAction('[Aws Customers] Detail dialog opened', props<{ dialogId: string }>());
export const closeCustomerDialog = createAction('[Aws Customers] Close detail dialog');

export const saveCustomer = createAction('[Aws Customers] Save customer', props<{ customer: AwsCustomerDTO }>());
export const saveCustomerCompleted = createAction('[Aws Customers] Save customer completed', props<{ customer: AwsCustomerDTO }>());
export const saveCustomerFailed = createAction('[Aws Customers] Save customer failed', props<{ error: any }>());
