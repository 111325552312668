import { Component, Inject, OnChanges, OnDestroy, OnInit, Optional } from "@angular/core";
import { FormControl } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { AlertService } from "src/app/commons/services/alert.service";
import * as UserActions from "src/app/store/actions/user.actions";
import { AppState } from "src/app/store/reducers";
import { environment } from "src/environments/environment";

@Component({
  selector: "identifai-tutorial",
  templateUrl: "./tutorial.component.html",
  styleUrls: ["./tutorial.component.scss"],
})
export class TutorialComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  tutorialUrl = environment.tutorialUrl;

  stopCtrl: FormControl = new FormControl(true);

  constructor(
    private store$: Store<AppState>,
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close() {
    this.store$.dispatch(UserActions.hideTutorial({ stop: this.stopCtrl.value }))
  }
}
