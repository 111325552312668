import { GraphMultiSeries } from 'src/app/helpers/graph.utils';
import { environment } from 'src/environments/environment';

import { Base, BaseDTO } from './base.model';
import { ClassificationOptions, Image, ImageDTO, VerdictDTO } from './image.model';
import { ClassificationResult, ClassificationType } from './request.model';

export type VideosGraphData = GraphMultiSeries[];

export type LLMResults = {
  elapsed: number;
  summary: string;
}

export type VideoClassificationResults = {
  pre_process: number,
  images: ClassificationResult[][],
  std_deviations: { [key: string]: number };
  llm_results: LLMResults;
}

export interface VideoDTO extends BaseDTO {
  hash: string;
  name?: string;
  path?: string;
  frames: number;
  false_positive: boolean;
  confidence?: number;
  version?: string;
  user_id: number;
  results: VideoClassificationResults;
  images: ImageDTO[];
  classification_options: ClassificationOptions;
  images_count: number;

  verdicts: VerdictDTO[];
}

export class Video extends Base {
  hash: string;
  name?: string;
  private _path?: string;
  frames: number;
  falsePositive: boolean;
  version?: string;
  userId: number;
  verdicts: VerdictDTO[];
  results: VideoClassificationResults;
  images: Image[];
  imagesCount: number;
  classificationOptions: ClassificationOptions;

  constructor(source: VideoDTO) {
    super(source);
    if (source) {
      this.hash = source.hash;
      this.name = source.name;
      this._path = source.path;
      this.frames = source.frames;
      this.falsePositive = source.false_positive;
      this.version = source.version;
      this.userId = source.user_id;
      this.verdicts = source.verdicts;
      this.results = source.results;
      this.images = source.images?.map(i => new Image(i));
      this.classificationOptions = source.classification_options;
      this.imagesCount = source.images_count;
    }
  }

  toDTO(): VideoDTO {
    let result: VideoDTO = <VideoDTO>super.toDTO();
    result.hash = this.hash;
    result.name = this.name;
    result.path = this._path;
    result.frames = this.frames;
    result.version = this.version;
    result.user_id = this.userId;
    result.verdicts = this.verdicts;
    result.results = this.results;
    result.images = this.images?.map(i => i.toDTO());
    result.classification_options = this.classificationOptions;
    result.images_count = this.imagesCount;
    return result;
  }

  get path(): string {
    if (this._path)
      return environment.baseUrl + '/api/store/' + this._path;
  }

  getVerdict(heuristic?: string): VerdictDTO | undefined {
    if (this.verdicts?.length) {
      return this.verdicts.find(v => v.heuristic == heuristic) || this.verdicts[0];
    }
    return undefined;
  }

  getClassification(heuristic?: string): ClassificationType {
    return this.getVerdict(heuristic)?.classification;
  }

  getConfidence(heuristic: string): number {
    return this.getVerdict(heuristic)?.confidence;
  }

  getClassifiedFrames(heuristic: string): number {
    return this.getVerdict(heuristic)?.count;
  }

  get usedModels(): string[] {
    return this.getVerdict()?.models || [];
  }

  get allModels(): string[] {
    let models: string[] = [];
    if (this.results) {
      Object.keys(this.results.std_deviations).forEach(key => {
        models.push(key);
      });
    }
    return models;
  }

  get biasedModels(): string[] {
    return this.allModels.filter(model => !this.usedModels.includes(model));
  }

  get description(): string {
    return this.results?.llm_results?.summary;
  }
}
