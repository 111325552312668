import { createSelector } from '@ngrx/store';

import { selectAwsCustomerState } from '../reducers';
import { AwsCustomerState } from '../reducers/aws_customer.reducer';

export const getAwsCustomersTableState = createSelector(
  selectAwsCustomerState,
  (state: AwsCustomerState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getAwsCustomers = createSelector(
  selectAwsCustomerState,
  (state: AwsCustomerState) => state.list
);

export const getTotalAwsCustomers = createSelector(
  getAwsCustomersTableState,
  (tableState) => tableState.total
);

export const getAwsCustomersCurrentPage = createSelector(
  getAwsCustomersTableState,
  (tableState) => tableState.currentPage
);

export const getAwsCustomersPerPage = createSelector(
  getAwsCustomersTableState,
  (tableState) => tableState.perPage
);

export const getAwsCustomersOrder = createSelector(
  getAwsCustomersTableState,
  (tableState) => tableState.order
);

export const getAwsCustomersDirection = createSelector(
  getAwsCustomersTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getAwsCustomersTableState,
  (tableState) => tableState.filters
);

export const getAwsCustomerDialogId = createSelector(
  selectAwsCustomerState,
  (state: AwsCustomerState) => state.dialogId
);
