import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { environment } from 'src/environments/environment';

import { toFormData } from 'src/app/helpers/form.utils';
import { AwsCustomerDTO, AwsCustomerFilters } from '../../models/aws_customer.model';


@Injectable({
  providedIn: "root"
})
export class LaravelAwsCustomerService {

  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      list: environment.baseUrl + "/api/aws_customers",
      store: environment.baseUrl + "/api/aws_customer",
    };
  }

  public list(page?: number,
    per_page?: number,
    order?: string,
    direction?: string,
    filters?: AwsCustomerFilters,
    includes?: string[]
  ): Observable<ListResultDTO<AwsCustomerDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (includes) params["includes[]"] = includes;
    if (filters) {
      if (filters.user) params["user_id"] = "" + filters?.user.id;
      if (filters.search) params["search"] = filters.search;
    }
    if (per_page) {
      params["per_page"] = "" + per_page;
      if (page) params["page"] = "" + page;
      return this.httpClient.get<ListResultDTO<AwsCustomerDTO>>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      });
    } else {
      return this.httpClient.get<AwsCustomerDTO[]>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      }).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }

  public upsert(customer: AwsCustomerDTO): Observable<AwsCustomerDTO> {
    if (customer.id) {
      return this.httpClient.post<AwsCustomerDTO>(this.ROUTES.store,
        toFormData(customer, 'PUT')
      );
    } else {
      return this.httpClient.post<AwsCustomerDTO>(this.ROUTES.store,
        toFormData(customer)
      );
    }
  }
}
